<template>
  <div class="bidding_detail_wrapper">
    <!-- <a-breadcrumb>
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>招投标</a-breadcrumb-item>
      <a-breadcrumb-item>招投标详情</a-breadcrumb-item>
    </a-breadcrumb>-->
    <div class="commom_title">
      <a-row type="flex">
        <a-col :span="1">
          <div class="commom_line"></div>
        </a-col>
        <a-col :span="6">招投标信息详情</a-col>
      </a-row>
    </div>
    <div class="content">
      <div class="title">{{ data.title }}</div>
      <div class="details_table">
        <div class="cell">项目名称：{{ data.title }}</div>
        <div class="cell">项目状态：{{ dataStatus }}</div>
        <div class="cell">项目地点：{{ (data.place || '').replaceAll(',', '-') }}</div>
      </div>
      <div class="details_table">
        <div class="cell">发布企业：{{ (data.enterpriseInfo || {}).enterpriseName }}</div>
        <div class="cell">发布类型：{{ data.type }}</div>
        <div class="cell">发布时间：{{ formatTime(data.createTime) }}</div>
      </div>
      <div class="text-content" v-html="data.comment"></div>
      <div class="file-content" v-if="fileList.length">
        <div class="file-label">资料附件：</div>
        <div class="file-name-content">
          <div class="file-item-name" v-for="item in fileList" :key="item.uid" @click="handleDown(item.path)">
            {{ item.fileName }}
          </div>
        </div>
      </div>
      <template v-if="dataType !== '2'">
        <h3>资质需求：</h3>
        <div class="text-content" v-html="data.ask"></div>
        <div class="btn-box" v-if="btnShow">
          <a-button type="primary" @click="$refs.VForm.createModel(data)">响应该项目</a-button>
        </div>
      </template>
    </div>
    <VForm ref="VForm" />
  </div>
</template>

<script>
import { getDetailsById, queryOneByCondition } from '@/api/TenderingBidding'
import moment from 'moment'
import VForm from './form.vue'
import { zbPubStatus } from '@/views/TenderingBidding/dict'

export default {
  name: 'Detial',
  components: {
    VForm
  },
  data () {
    return {
      data: {
        time: null,
        zbPubStatus
      }
    }
  },
  created () {
    this.init()
  },
  computed: {
    fileList () {
      if (this.data.fileUrl) {
        const data = JSON.parse(this.data.fileUrl)
        if (this.dataType === '2') {
          return data.map((item, index) => ({
            path: item.filePath,
            uid: index,
            ...item
          }))
        } else {
          return data.map((item) => {
            const arr = item.path.split('.')
            return {
              ...item,
              fileName: item.fileName + '.' + arr[arr.length - 1]
            }
          })
        }
      }
      return []
    },
    btnShow () {
      const isLogin = this.isLogin
      if (!isLogin) return isLogin
      const enterpriseId = this.$store.getters.userInfo.enterpriseInfoDto.enterpriseId
      return enterpriseId !== this.data.publishEnterprise?.enterpriseId && this.data.publishStatus == '2'
    },
    dataStatus () {
      const data = zbPubStatus.find((item) => item.value == (this.data.status || this.data.publishStatus))
      return data ? data.label : ''
    }
  },
  methods: {
    formatTime (time) {
      return time && moment(time).format('YYYY-MM-DD')
    },
    handleDown (path) {
      window.open(path)
    },
    init () {
      this.$spin.show()
      const { id, dataType } = this.$route.query
      this.dataType = dataType
      if (dataType === '2') {
        queryOneByCondition({
          anId: id,
          status: '2,3'
        })
          .then((res) => {
            this.data = {
              ...res.data.announcement,
              ...res.data,
              comment: res.data.info
            }
          })
          .finally(() => {
            this.$spin.hide()
          })
      } else {
        getDetailsById(id)
          .then((res) => {
            this.data = res.data
          })
          .finally(() => {
            this.$spin.hide()
          })
      }
    }
  }
}
</script>

<style lang="less">
@import url('../../../common/style');
.bidding_detail_wrapper {
  .btn-box {
    height: 100px;
    line-height: 100px;
    text-align: center;
    border-top: 1px solid #cccccc;
  }
  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .file-content {
    border-top: 1px solid #cfdde4;
    padding-top: 12px;
    margin-top: 10px;
    display: flex;
    font-size: 16px;
    .file-label {
      margin-right: 20px;
    }
    .file-item-name {
      margin-bottom: 5px;
      color: #1677ff !important;
      cursor: pointer;
    }
  }
  .title {
    width: 100%;
    text-align: center;
    font-size: 28px;
    padding: 0 143px;
    font-weight: 500;
    line-height: 33px;
    color: #333333;
    opacity: 1;
  }
  .lable {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 16px;
    margin-top: 20px;
    .province {
      margin-right: 55px;
    }
  }
  .details_table {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .cell {
      width: 33.3%;
      height: 60px;
      line-height: 60px;
      text-indent: 5px;
      margin-top: 20px;
      padding-left: 28px;
      border: 1px solid #deecff;
    }
  }
  .text-content {
    flex: 1;
    margin-top: 20px;
    min-height: 143px;
    overflow-x: hidden;
    overflow-y: auto;
    font-size: 18px;
    color: #898989;
    font-weight: 500;
    text-indent: 50px;
    table {
      width: 100%;
      border-top-width: 1px;
      border-right-width: 1px;
      border-bottom-width: 0px;
      border-left-width: 0px;
      border-top-style: solid;
      border-right-style: solid;
      border-bottom-style: solid;
      border-left-style: solid;
      border-top-color: #efefef;
      border-right-color: #efefef;
      border-bottom-color: #efefef;
      border-left-color: #efefef;
      margin-top: 8px;
      margin-bottom: 8px;
      padding: 0px;
      margin-right: 0px;
      margin-left: 0px;
      tr > td {
        width: auto;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: #efefef;
        border-top-width: 0px;
        border-right-width: 0px;
        border-left-width: 1px;
        border-left-style: solid;
        border-left-color: #efefef;
        margin: 0px;
        border-top-style: none;
        padding: 4px;
      }
    }
  }
}
</style>
