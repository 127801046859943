<template>
  <a-modal
    :visible="visible"
    :title="title"
    @cancel="onCancel"
    width="1200px"
    v-bind="modalProps"
    class="tendering-model">
    <a-form-model
      :model="form"
      ref="form"
      layout="vertical"
      labelAlign="right"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 18 }">
      <div class="basic-form">
        <div class="form-title">联系信息</div>
        <a-row>
          <a-col :span="8">
            <a-form-model-item
              label="联系人"
              :prop="'name'"
              :rules="{ required: true, message: '不能为空' }"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 16 }"
            >
              <a-input :disabled="isLook" v-model="form.name" placeholder="联系人" :maxlength="50" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
              label="性别"
              :prop="'gender'"
              :rules="{ required: true, message: '不能为空' }"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 16 }"
            >
              <a-radio-group v-model="form.gender" :disabled="isLook">
                <a-radio value="男"> 男 </a-radio>
                <a-radio value="女"> 女 </a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
              label="联系电话"
              :prop="'phone'"
              :rules="{ required: true, message: '不能为空' }"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 16 }"
            >
              <a-input v-model="form.phone" :disabled="isLook" placeholder="联系电话" :maxlength="50" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </div>
      <div class="basic-form" style="margin-top: 20px">
        <div class="form-title">响应企业具备资质文件上传</div>
        <template v-for="(item, index) in (form.fileList || [])">
          <a-form-model-item
            :key="index"
            :label="item.title"
            :prop="`fileList.${index}.url`"
            :rules="{ required: true, message: '请上传资质文件' }"
          >
            <div class="img-content" v-if="isLook">
              <img v-for="(file,idx) in item.url" :key="idx" :src="file.filePath" alt="" class="image-look-item" />
            </div>
            <VUpload v-else v-model="item.url" />
          </a-form-model-item>
        </template>
        <a-form-model-item label="备注">
          <a-textarea placeholder="请输入备注" :disabled="isLook" v-model="form.remark"></a-textarea>
        </a-form-model-item>
      </div>
    </a-form-model>
    <template #footer>
      <a-button @click="onCancel">取消</a-button>
      <a-button type="primary" @click="handleSubmit" :loading="loadingSave">提交</a-button>
    </template>
  </a-modal>
</template>

<script>
import { AnnouncementResponseController } from '@/api/TenderingBidding'
import VUpload from '@/components/common/v-upload'

export default {
  name: 'TenderingForm',
  components: {
    VUpload
  },
  data () {
    return {
      loadingSave: false,
      visible: false,
      form: {
        fileList: []
      },
      isLook: false
    }
  },
  computed: {
    title () {
      return this.isLook ? '响应详情' : '响应项目'
    },
    modalProps () {
      if (this.isLook) {
        return { footer: null }
      }
      return {}
    }
  },
  methods: {
    createModel (rowItem) {
      this.isLook = false
      this.$set(this, 'form', {
        anId: rowItem.id,
        gender: '男',
        enterpriseId: this.$store.getters.userInfo.enterpriseInfoDto.enterpriseId,
        enterpriseName: rowItem.enterpriseInfo.enterpriseName,
        userId: this.$store.getters.userInfo.userId,
        fileList: [...rowItem.fileList].map((item) => ({ ...item, url: [] }))
      })
      this.visible = true
    },
    look (rowItem) {
      this.isLook = true
      this.$set(this, 'form', {
        ...rowItem,
        fileList: JSON.parse(rowItem.fileList || '[]')
      })
      this.visible = true
    },
    onCancel () {
      this.visible = false
      this.$refs.form.resetFields()
      this.loadingSave = false
    },
    handleSubmit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const fileList = this.form.fileList.map((item) => {
            const { title, url } = item
            const urlList = url
              .filter((file) => file.status === 'done')
              .map((file) => ({
                fileName: file.response.realName,
                filePath: file.response.path
              }))

            return {
              title,
              url: urlList
            }
          })
          const model = {
            ...this.form,
            fileList: fileList
          }
          this.loadingSave = true
          AnnouncementResponseController(model)
            .then((res) => {
              this.$message.success('招标响应成功')
              this.onCancel()
            })
            .finally(() => {
              this.loadingSave = false
            })
        }
      })
    }
  }
}
</script>

<style lang="less">
.tendering-model {
  .basic-form {
    border: 1px solid #d9d9d9;
    .ant-form-item{
      display: flex;
    }
    .ant-form-item-label{
      padding: 5px  8px 0 8px !important;
      text-align: right;
      label{
        display: inline-block;
        text-align: left;
      }
    }
    .ant-radio-group{
     padding-top: 5px;
    }

    .form-title {
      height: 60px;
      line-height: 60px;
      padding: 0 20px;
      color: #3d3d3d;
      font-size: 16px;
      border-bottom: 1px solid #d9d9d9;
      margin-bottom: 30px;
    }
    &-item {
      margin-top: 24px;
      display: flex;
      align-items: baseline;
      width: 930px;
      &-title {
        width: 155px;
        text-align: right;
      }
      &-content {
        width: 700px;
        border: 1px solid #d9d9d9;
        position: relative;
        top: -15px;
        padding: 20px 0;
      }
    }
  }
  .add-box {
    padding: 20px;
    padding-top: 0;
    display: flex;
    align-items: center;
    .tips {
      color: #999999;
      margin-left: 20px;
      font-size: 12px;
    }
  }
  .delete-icon {
    position: absolute;
    right: -30px;
    top: 3px;
    color: #e21414;
    cursor: pointer;
  }
  .image-look-item{
    width: 104px;
    height: 104px;
    margin-right: 10px;
  }
}
</style>
